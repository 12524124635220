import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AuthProvider } from "react-auth-kit";
import { MantineProvider } from '@mantine/core';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider
      authType={"cookie"}
      authName={"access_token"}
      cookieDomain={window.location.hostname}
      cookieSecure={false}
    >
      <Provider store={store}>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={{
          colors:{
            'black': ['#000', '#111', '#222', '#333', '#444', '#555', '#666', '#777', '#888', '#999',],
          }
        }}>
          <App />
        </MantineProvider>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);
