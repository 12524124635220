import React from 'react'

const QualifiedParticipantsBox = ({id,img,name,order,url}:{id:string,img:string,name:string,url:string,order:number}) => {
  return (
    <a href={`https://linkedin.com${url}`} target='_blank'  className="flex flex-col relative items-center justify-center shadow-md m-2 bg-white/80 hover:bg-white/90 hover:cursor-pointer rounded-md w-[155px] h-[155px] transition duration-300 ease-in-out">
      <img src={img} alt="" className="w-28 h-28 rounded-full" />
      <p className='text-white absolute right-1 top-1 bg-black/40 shadow-md rounded-full h-5 pt-[2px] w-5 text-center text-xs font-bold'>{order}</p>
      <h1 className="text-black text-sm font-semibold w-full truncate px-2 text-center">{name}</h1>
    </a>
  )
}

export default QualifiedParticipantsBox