import React, { useEffect, useRef } from "react";
import Navbar from "../components/sections/Navbar";
import HeroSection from "../components/sections/HeroSection";
import HiringInputs from "../components/forms/HiringInputs";
import Footer from "../components/sections/Footer";
import { useGetScrapStatusMutation, useScrapAllMutation } from "../redux/services/JobPostApi";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setScrappedDataIsLoading } from "../redux/features/JobPostsSlice";
import NewNavbar from "../components/sections/NewNavbar";
import NewTextGenForm from "../components/forms/NewTextGenForm";

function HomePage() {
  const jobposting = useRef(null);
  const dispatch = useDispatch();
  // Scraps all the posts and participants for the active account
  const [scrapAll, response3] = useScrapAllMutation();
  const [checkScrapStatus, response5] = useGetScrapStatusMutation();
  const activeAccount = useSelector(
    (state: RootState) => state.hirePeople.activeAccount
  );
  const isScrapping = useSelector(
    (state: RootState) => state.hirePeople.scrappedDataIsLoading
  );
  const scrapAllPost = async () => {
    try {
      if(isScrapping === false){
        dispatch(setScrappedDataIsLoading(true))
        const payload = await scrapAll({ "account": activeAccount }).unwrap()
        console.log("Scrapped All Response: ")
        console.log(payload)
      }
      const payload2 = await checkScrapStatus({ "account": activeAccount }).unwrap()
      if(payload2?.data.status === false){
        dispatch(setScrappedDataIsLoading(false))
      }
    } catch (error) {
      dispatch(setScrappedDataIsLoading(false))
      console.log('rejected', error)
    }
  }
  useEffect(() => {
    // scrapAllPost()
  }, [activeAccount])
  return (
    <div className="bg-gradient-to-t from-cyan-500 to-zinc-600 min-h-screen h-full flex flex-col relative">
      <NewNavbar />
      <HeroSection />
      {/* <HiringInputs jobposting={jobposting} /> */}
      <NewTextGenForm />
      <Footer />
    </div>
  );
}

export default HomePage;
