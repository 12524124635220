import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

interface userSliceState {
  user: null | string;
  company: null | string;
  token: null | string;
}

const initialState: userSliceState = {
  user: null,
  company: null,
  token: null,
};

export const userSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    setUserName: (state, { payload }) => {
      state.user = payload;
    },
    setCompanyName: (state, { payload }) => {
      state.company = payload;
    },
    setToken: (state, action: PayloadAction<any>) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setUserName, setCompanyName, setToken } = userSlice.actions;

export default userSlice.reducer;
