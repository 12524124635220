import {
  createAsyncThunk,
  createSlice,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import axios from "axios";
import { profile } from "console";

interface JobAdParams {
  BASE_URL: string;
  token: string;
  jobBody: Object;
}

interface InitialStateProps {
  hiringTextEN: null | string;
  hiringTextTR: null | string;
  hiringResponseLoading: boolean;
  errors: null | string | any;
}

const initialState: InitialStateProps = {
  hiringTextEN: null,
  hiringTextTR: null,
  hiringResponseLoading: false,
  errors: null,
};

export const createJobAd = createAsyncThunk(
  "createJobAd",
  async (
    {
      BASE_URL,
      token,
      jobTitle,
      location,
      spokenLanguages,
      yearsOfExperiance,
      degree,
      yearsInCurrentRole,
      yearsInCurrentCompany,
      skills,
      industries,
      profileLanguage,
      additionalRequirements,
      jobType,
      militaryServiceStatus,
    }: any,
    ThunkAPI
  ) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/linkedin/listing`,
        {
          jobTitle: jobTitle,
          location: location,
          spokenLanguages: spokenLanguages,
          yearsOfExperiance: yearsOfExperiance,
          degree: degree,
          yearsInCurrentRole: yearsInCurrentRole,
          yearsInCurrentCompany: yearsInCurrentCompany,
          skills: skills,
          industries: industries,
          profileLanguage: profileLanguage,
          additionalRequirements: additionalRequirements,
          jobType: jobType,
          militaryServiceStatus: militaryServiceStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data.data;
    } catch (error: any) {
      throw ThunkAPI.rejectWithValue({ message: error.response.data.errors });
    }
  }
);

export const JobPostCreationSlice = createSlice({
  name: "JobPostCreationSlice",
  initialState,
  reducers: {
    setErrorsToNull: (state) => {
      state.errors = null;
      state.hiringTextEN = null;
      state.hiringTextTR = null;
      state.hiringResponseLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createJobAd.pending, (state) => {
      state.hiringResponseLoading = true;
      state.errors = null;
    });
    builder.addCase(createJobAd.fulfilled, (state, { payload }) => {
      state.hiringTextEN = payload?.aiResponse;
      state.hiringTextTR = payload?.aiResponseTR;
      state.hiringResponseLoading = false;
      state.errors = null;
    });
    builder.addCase(createJobAd.rejected, (state, action: any) => {
      state.hiringResponseLoading = false;
      state.errors = action?.payload?.message;
    });
  },
});

export const { setErrorsToNull } = JobPostCreationSlice.actions;

export default JobPostCreationSlice.reducer;
