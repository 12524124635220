import logo from "../../assets/beyazcerelogo.svg";

function Footer() {
  return (
    <div className="flex flex-row items-center justify-center py-4  ">
      <p className="text-white text-sm">
        <span className="text-lg font-medium">Cere</span> | © 2023 Cerebrum
        Technologies | All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
