import React from "react";
import { RootState, useAppDispatch } from "../../redux/store";
import {
  applicants,
  qualifiedParticipants,
  setFilteredQualifiedParticipants,
  setListingType
} from "../../redux/features/JobPostsSlice";
import Cookies from "js-cookie";
import { IJobPostsItem, IParticipant } from "../../types/JobPostTypes";
import { setActiveJobPostID, setActiveJobPostParticipants } from '../../redux/features/JobPostsSlice'
import { useSelector } from "react-redux";
import { IconCheckupList, IconChevronUpRight, IconClipboardList, IconFilterFilled, IconFilterPlus, IconFilterSearch } from "@tabler/icons-react";

function JobPostBox({ url, aciklama, email, is_turu, jobid, konum, sirket, unvan, participants,open,qualified }: IJobPostsItem) {
  const token = Cookies.get("access_token");

  console.log(jobid)
  const dispatch = useAppDispatch();

  const listingType = useSelector(
    (state: RootState) => state.hirePeople.listingType
  );
  
  const activeJobPostId = useSelector(
    (state: any) => state.hirePeople.activeJobPostID
  );

  console.log(qualified)
  

  console.log("activeJobPostId: ", activeJobPostId)

  console.log("Listing Type: ", listingType)
  const initializeJobPost = () => {
    dispatch(setActiveJobPostID(jobid))
    dispatch(setActiveJobPostParticipants(participants))
    dispatch(setFilteredQualifiedParticipants(qualified))
  }


  return (
    <div
      className={`bg-white  rounded-md shadow-md p-3 ${activeJobPostId === jobid ? 'ring-[4px] ring-green-500': ''} flex flex-col relative min-w-[190px] max-w-[300px] hover:bg-white/80 w-full justify-between space-y-4 cursor-pointer active:ring-4 active:ring-white/50 m-2 transition duration-300 ease-in-out`}
      onClick={initializeJobPost}
      key={jobid}
    >
      <div className="flex flex-col items-start justify-center">
        <h1 className="text-base font-medium truncate">
          {unvan}
        </h1>
        <hr className="w-full border-black my-2" />
        <h1 className="text-base text-black/90 font-medium truncate">
          {sirket}
        </h1>
      </div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row">
          <button className="mr-1 " onClick={()=> dispatch(setListingType('participants'))}>
            <IconClipboardList className={`w-5 h-5  text-black/80 hover:text-black   duration-300 ease-out ${activeJobPostId === jobid && listingType === 'participants' ? 'text-green-500':'' } `} />
          </button>
          <button className="mx-1" onClick={open}>
            <IconFilterPlus className={`w-5 h-5  text-black/80 hover:text-black   duration-300 ease-out `} />
          </button>
          <button className="mx-1"  onClick={()=> dispatch(setListingType('qualified'))}>
            <IconCheckupList className={`w-5 h-5 text-black/80 hover:text-black  duration-300 ease-out ${activeJobPostId === jobid && listingType === 'qualified' ? 'text-green-500':'' }`} />
          </button>
        </div>
      <a
        href={`${url}`}
        className="self-end"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconChevronUpRight className=" absolute right-3 bottom-3 w-5 h-5 hover: text-black duration-300 ease-out"/>
      </a>
      </div>
      
    </div>
  );
}

export default JobPostBox;
