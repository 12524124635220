import React, { useState } from 'react'
import logo from "../../assets/beyazcerelogo2.svg";
import { useLocation, useNavigate, Link, Location } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu, Modal, NativeSelect, Select, Text } from '@mantine/core';
import { IconArrowsLeftRight, IconCircleLetterT, IconLogout, IconMenu2, IconMessageCircle, IconPhoto, IconSearch, IconSelect, IconSettings, IconTrash, IconUserPlus } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { AddAccountForm } from '../forms/AddAccountForm';
import { RootState } from '../../redux/store';
import { setActiveAccount } from '../../redux/features/JobPostsSlice';

const NewNavbar = () => {
  const page = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signOutFromApp = useSignOut();
  const [opened, { open, close }] = useDisclosure(false)
  const [opened2, { open: open2, close: close2 }] = useDisclosure(false)
  const signOut = () => {
    //localStorage.removeItem("persist:user");
    signOutFromApp();
  };
  const accounts = useSelector(
    (state: RootState) => state?.hirePeople?.accounts
  );
  const activeAccount = useSelector(
    (state: RootState) => state.hirePeople.activeAccount
  );
  // const [selected, setSelected] = useState<string | null>(activeAccount ? activeAccount : null)
  // let data: any = accounts?.map((account: string) => {
  //   if (account !== undefined)
  //     return { value: account, label: account }
  // })
  console.log("Accounts: ", accounts)
  console.log("Active account: ", activeAccount)

  return (
    <div className="flex flex-row justify-between items-center w-full mx-auto  md:w-5/6 border-b-[1px] border-white/20">
      <div className='flex flex-row items-center justify-start space-x-3'>
        {/* image */}
        <div className='flex flex-row items-center justify-center mr-12'>
          <button onClick={() => navigate("/")}>
            <img src={logo} alt="logo" className="w-16 h-16" />
          </button>
        </div>
        <div className='flex flex-row items-center space-x-5'>
          <Link to='/'>
            <Text size='md' weight={600} sx={{ "&:hover": { color:  '#000000' } }} className='text-white'>Generate Post</Text>
          </Link>
          {/* <Link to='/hire-people'>
            <Text size='md' weight={600}
              sx={{ "&:hover": { color:  '#000000' } }} className='text-white'>View Posts</Text>
          </Link> */}
        </div>
      </div>
      <Menu shadow="md" width={220}>
        <Menu.Target>
          <Button sx={{
            "&:hover": {
              backgroundColor: "transparent",
              color: "black",
            }
          }}>
            <IconSettings size={24} />
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {/* <Menu.Item icon={<IconSettings size={16} />}>Settings</Menu.Item> */}
          <Menu.Item icon={<IconCircleLetterT size={16} />}>Tokens : 13</Menu.Item>
          <Menu.Item onClick={open} icon={<IconUserPlus size={16} />}>
            Add Account
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={open2} icon={<IconSelect size={16} />}>{activeAccount}</Menu.Item>
          <Menu.Item onClick={signOut} color="red" icon={<IconLogout size={16} />}>Log out</Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <Modal opened={opened} onClose={close} withCloseButton={false}>
        <AddAccountForm />
      </Modal>
      {/* <Modal opened={opened2} onClose={close2} withCloseButton={false}>
        <div className='h-[200px] w-full'>
          <p className='mb-4'>Select Account</p>
          <Select
            placeholder='Select account'
            height={600}
            data={data ? data : []}
            value={activeAccount}
            onChange={() => dispatch(setActiveAccount(selected))}
          />
        </div>
      </Modal> */}
    </div>
  )
}

export default NewNavbar