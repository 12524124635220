export const languages = [
  "English",
  "Spanish",
  "French",
  "German",
  "Italian",
  "Portuguese",
  "Russian",
  "Chinese",
  "Japanese",
  "Korean",
  "Arabic",
  "Hindi",
  "Turkish",
  "Greek",
  "Ukrainian",
  "Polish",
  "Dutch",
  "Africans",
];

export const textLanguages = [
  "English",
  "Turkish",
];

export const degrees = [
  "High School",
  "Associates",
  "Bachelors",
  "Masters",
  "PhD",
];

export const jobTypes = [
  "Full-time",
  "Part-time",
  "Contract",
  "Temporary",
  "Internship",
  "Volunteer",
  "Other",
];

export const experienceLevels = [
  "Entry level",
  "Associate",
  "Mid-Senior level",
  "Director",
  "Executive",
  "Internship",
  "Not Applicable"
];

export const companyNames =[ 
  "Eczacıbaşı Bilişim",
  "Eczacıbaşı Ekom",
  "Eczacıbaşı Gayrimenkul",
  "Decoverse",
  "Eczacıbaşı Holding",
  "Eczacıbaşı İlaç Pazarlama",
  "Eczacıbaşı İlaç, Sinai ve Finansal Yatırımlar",
  "Eczacıbaşı Tüketim Ürünleri",
  "Eczacıbaşı Yapı Gereçleri",
  "VitrA Karo",
  "İntema",
  "Gensenta",
  "Esan",
  "Eczacıbaşı-Monrol Nükleer Ürünler",
]

export const jobFunctions = [
  "Accounting",
  "Administrative",
  "Arts and Design",
  "Business Development",
  "Community and Social Services",
  "Consulting",
  "Education",
  "Engineering",
  "Entrepreneurship",
  "Finance",
  "Healthcare Services",
  "Human Resources",
  "Information Technology",
  "Legal",
  "Marketing",
  "Media and Communication",
  "Military and Protective Services",
  "Operations",
  "Product Management",
  "Program and Project Management",
  "Purchasing",
  "Quality Assurance",
  "Real Estate",
  "Research",
  "Sales",
  "Support",
  "Other",
]

export const workPlaces = [
  "On-Side",
  "Remote",
  "Hybrid",
]

export const companyIndustries = [
  "Education Management",
  "E-Learning",
  "Higher Education",
  "Primary/Secondary Education",
  "Research",
  "Building Materials",
  "Civil Engineering",
  "Construction",
  "Architecture & Planning",
  "Design",
  "Graphic Design",
  "Accounting",
  "Business Supplies & Equipment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Human Resources",
  "Information Services",
  "Management Consulting",
  "Outsourcing/Offshoring",
  "Professional Training & Coaching",
  "Security & Investigations",
  "Staffing & Recruiting",
  "Retail",
  "Supermarkets",
  "Wholesale",
  "Mining & Metals",
  "Oil & Energy",
  "Utilities",
  "Automotive",
  "Aviation & Aerospace",
  "Chemicals",
  "Defense & Space",
  "Electrical & Electronic Manufacturing",
  "Food Production",
  "Glass, Ceramics & Concrete",
  "Industrial Automation",
  "Machinery",
  "Mechanical or Industrial Engineering",
  "Packaging & Containers",
  "Paper & Forest Products",
  "Plastics",
  "Railroad Manufacture",
  "Renewables & Environment",
  "Shipbuilding",
  "Textiles",
  "Banking",
  "Capital Markets",
  "Financial Services",
  "Insurance",
  "Investment Banking",
  "Investment Management",
  "Venture Capital & Private Equity",
  "Airlines/Aviation",
  "Gambling & Casinos",
  "Hospitality",
  "Leisure, Travel & Tourism",
  "Restaurants",
  "Recreational Facilities & Services",
  "Sports",
  "Arts & Crafts",
  "Fine Art",
  "Performing Arts",
  "Photography",
  "Biotechnology",
  "Hospital & Health Care",
  "Medical Device",
  "Medical Practice",
  "Mental Health Care",
  "Pharmaceuticals",
  "Veterinary",
  "Computer Hardware",
  "Computer Networking",
  "Nanotechnology",
  "Semiconductors",
  "Telecommunications",
  "Wireless",
  "Commercial Real Estate",
  "Real Estate",
  "Alternative Dispute Resolution",
  "Legal Services",
  "Apparel & Fashion",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Food & Beverages",
  "Furniture",
  "Luxury Goods & Jewelry",
  "Sporting Goods",
  "Tobacco",
  "Wine and Spirits",
  "Dairy",
  "Farming",
  "Fishery",
  "Ranching",
  "Market Research",
  "Marketing & Advertising",
  "Newspapers",
  "Online Media",
  "Printing",
  "Public Relations & Communications",
  "Publishing",
  "Translation & Localization",
  "Writing & Editing",
  "Civic & Social Organization",
  "Fundraising",
  "Individual & Family Services",
  "International Trade & Development",
  "Libraries",
  "Museums & Institutions",
  "Non-Profit Organization Management",
  "Philanthropy",
  "Program Development",
  "Religious Institutions",
  "Think Tanks",
  "Computer & Network Security",
  "Computer Software",
  "Information Technology & Services",
  "Internet",
  "Import & Export",
  "Logistics & Supply Chain",
  "Maritime",
  "Package/Freight Delivery",
  "Transportation/Trucking/Railroad",
  "Warehousing",
  "Animation",
  "Broadcast"
]


// { 
//   "replyLanguage":"English", //required +          +
//   "companyName":"Eczacıbaşı Bilişim", //required   +
//   "jobTitle":"backend developer", //required       +
//   "workPlaceType":"On-Side", //required            +
//   "location":"Ankara, Turkey", //required          +
//   "jobFunction":"Engineering", //required          +
//   "employmentType":"Full-time", //required         +
//   "experienceLevel":"Mid-Senior level", //required +
//   "degree":"Bachelors", //optional                 +
//   "targetEmail":"alperenoz93@gmail.com", //optional
//   "yearsOfExperiance":2, //optional                +
//   "yearsInCurrentRole":2, //optional               +
//   "yearsInCurrentCompany":1, //optional            +
//   "skills":"Java, Mysql", //optional               +
//   "additionalRequirements":"friendly, team player," //optional +
// }


