import React, { useEffect, useState } from 'react'
import { z } from 'zod';
import { useForm, zodResolver } from '@mantine/form';
import { useGenerateJobPostMutation } from "../../redux/services/JobPostApi";
import { RootState, useAppDispatch } from "../../redux/store";
import { degrees, jobTypes, languages, experienceLevels, textLanguages, companyIndustries, companyNames, jobFunctions, workPlaces } from "../../data";
import { useDisclosure } from '@mantine/hooks';
import { useSelector } from 'react-redux';
import { setHiringTextEN, setHiringTextTR } from "../../redux/features/JobPostsSlice";
import { Button, Modal, NumberInput, Select, TextInput, Textarea, createStyles } from '@mantine/core';
import { LanguagesEnumType, CompanyNameEnumType, ExperienceLevelEnumType, WorkSpaceEnumType, JobFunctionEnumType, JobTypeEnumType, DegreeEnumType } from "../../types/JobPostTypes"
import { FaRegCopy, FaEye } from "react-icons/fa";
import { create } from 'domain';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const useStyles = createStyles((theme) => ({
  label: {
    marginBottom: 3,
    paddingLeft: 4,
    color: 'white',
    fontWeight: 600,
    fontSize: '14px',
    fontFamily: theme.fontFamily,
  }
}));

const schema = z.object({
  replyLanguage: z.nativeEnum(LanguagesEnumType),
  companyName: z.nativeEnum(CompanyNameEnumType),
  jobTitle: z.string().min(5, { message: 'Job title should have at least 5 letters' }).max(50, "Job title cannot be more than 50 characters"),
  workPlace: z.nativeEnum(WorkSpaceEnumType),
  jobLocation: z.string().min(3, { message: 'Job location should have at least 3 letters' }).max(50, "Job location must be less than 50 characters"),
  jobFunction: z.nativeEnum(JobFunctionEnumType),
  employmentType: z.nativeEnum(JobTypeEnumType),
  experienceLevel: z.nativeEnum(ExperienceLevelEnumType),
  degree: z.nativeEnum(DegreeEnumType),
  yearsOfExperiance: z.number().min(0, { message: 'Years of experience should be positive' }),
  yearsInCurrentRole: z.number().min(0, { message: 'Years in current role should be positive' }),
  yearsInCurrentCompany: z.number().min(0, { message: 'Years in current company should be positive' }),
  skills: z.string().optional(),
  additionalRequirements: z.string().optional(),
});

const NewTextGenForm = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const [
    generateJobPost,
    { isLoading: isGenerating, isSuccess: isGenerateSuccess, isError: isGenerateError, error: generateError },
  ] = useGenerateJobPostMutation();

  const [opened, { open, close }] = useDisclosure(false);
  const [isCopied, setIsCopied] = useState(false);
  const [responseLanguage, setResponseLanguage] = useState<string>("EN");

  const form = useForm({
    validate: zodResolver(schema),
    initialValues: {
      replyLanguage: "",

      companyName: "",
      jobTitle: "",

      workPlace: "",
      jobLocation: "",

      jobFunction: "",
      employmentType: "",
      experienceLevel: "",

      degree: "",
      yearsOfExperiance: 0,
      yearsInCurrentRole: 0,
      yearsInCurrentCompany: 0,

      skills: "",
      additionalRequirements: "",
    },
    validateInputOnChange: true,
  });

  const hiringTextEN = useSelector((state: RootState) => state?.hirePeople?.hiringTextEN);
  const hiringTextTR = useSelector((state: RootState) => state?.hirePeople?.hiringTextTR);

  let formattedTextEN = hiringTextEN ? hiringTextEN : "";
  // const formattedTextEN = hiringTextEN?.replace(/\n/g, "<br>") ?? "";
  let formattedTextTR = hiringTextTR ? hiringTextTR : "";
  // const formattedTextTR = hiringTextTR?.replace(/\n/g, "<br>") ?? "";

  const handleCopyToClipboard = () => {
    const textToCopy =
      responseLanguage === "EN" ? formattedTextEN : formattedTextTR;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  const generateJobPostRequest = async (values: typeof form.values) => {
    try {
      const payload = await generateJobPost({
        "replyLanguage": values.replyLanguage,
        "companyName": values.companyName,
        "jobTitle": values.jobTitle,
        "workPlaceType": values.workPlace,
        "location": values.jobLocation,
        "jobFunction": values.jobFunction,
        "employmentType": values.employmentType,
        "experienceLevel": values.experienceLevel,
        "degree": values.degree,
        "yearsOfExperiance": values.yearsOfExperiance,
        "yearsInCurrentRole": values.yearsInCurrentRole,
        "yearsInCurrentCompany": values.yearsInCurrentCompany,
        "skills": values.skills,
        "additionalRequirements": values.additionalRequirements,
      }).unwrap()
      console.log("payload: ", payload)
      dispatch(setHiringTextEN(payload?.data?.aiResponse))
      dispatch(setHiringTextTR(payload?.data?.aiResponse))
      console.log(isGenerateSuccess)
      if (payload !== undefined) {
        setTimeout(() => {
          open()
        }, 2000);
      }
      console.log("Generate Job Post Response: ", payload)
    } catch (error) {
      console.log('rejected', error)
    }
  }

  return (
    <>
    <form className='w-auto h-auto bg-black/20 self-center p-6 rounded-lg flex flex-col items-start space-y-6 space-' onSubmit={form.onSubmit((values, event) => generateJobPostRequest(values))}>
      <Select classNames={{ label: classes.label }} required w={'200px'} data={textLanguages} label="Job Post Text Language" {...form.getInputProps('replyLanguage')} />
      <div className='w-auto items-start flex flex-row space-x-5'>
        <Select classNames={{ label: classes.label }} required w={'200px'} data={companyNames} label="Company" {...form.getInputProps('companyName')} />
        <TextInput classNames={{ label: classes.label }} required w={'300px'} label="Job Title" {...form.getInputProps('jobTitle')} />
      </div>
      <div className='w-auto items-start flex flex-row space-x-5'>
        <Select classNames={{ label: classes.label }} required w={'200px'} data={workPlaces} label="Workplace Type" {...form.getInputProps('workPlace')} />
        <TextInput classNames={{ label: classes.label }} required w={'240px'} label="Job location" {...form.getInputProps('jobLocation')} />
      </div>
      <div className='w-auto items-start flex flex-row space-x-5'>
        <Select classNames={{ label: classes.label }} required w={'200px'} data={jobFunctions} label="Job Function" {...form.getInputProps('jobFunction')} />
        <Select classNames={{ label: classes.label }} required w={'200px'} data={jobTypes} label="Employment Type"  {...form.getInputProps('employmentType')} />
      </div>
      <div className='w-auto items-start flex flex-row space-x-5'>
        <Select classNames={{ label: classes.label }} required w={'200px'} data={experienceLevels} label="Seniority Level" {...form.getInputProps('experienceLevel')} />
        <Select classNames={{ label: classes.label }} required w={'130px'} data={degrees} label="Degree" {...form.getInputProps('degree')} />
      </div>
      <div className='w-auto items-start flex flex-row space-x-8'>
        <NumberInput classNames={{ label: classes.label }} max={20} w={'170px'} label="Years of experience" {...form.getInputProps('yearsOfExperiance')} />
        <NumberInput classNames={{ label: classes.label }} max={20} w={'200px'} label="Years in current company" {...form.getInputProps('yearsInCurrentRole')} />
        <NumberInput classNames={{ label: classes.label }} max={20} w={'170px'} label="Years in current role"{...form.getInputProps('yearsInCurrentCompany')} />
      </div>
      <div className='w-auto items-start flex flex-row space-x-6'>
        <Textarea classNames={{ label: classes.label }} autosize minRows={2} w={'300px'} label="Skills" {...form.getInputProps('skills')} />
        <Textarea classNames={{ label: classes.label }} autosize minRows={2} w={'300px'} label="Additional Requirements" {...form.getInputProps('additionalRequirements')} />
      </div>
      <div className="flex flex-row items-center justify-center self-center space-x-5">
        <Button size="md"
          variant="light"
          sx={{
            width: '240px',
            color: 'white',
            backgroundColor: 'black',
            '&:hover': {
              transform: 'scale(1.05)',
              backgroundColor: 'black',
              color: '#ffffff',
            },
          }}
          className="cursor-pointer rounded-lg duration-300 ease-in-out bg-black/80"
          loading={isGenerating} disabled={ form.isValid() === true ? false : true}
          type='submit'>
          {isGenerating === true ? 'Generating...' : 'Generate Job Post'}
        </Button>
        <Button size="md"
          variant="filled"
          sx={{
            width: '120px',
            color: 'white',
            backgroundColor: '#ffffff',
            '&:hover': {
              transform: 'scale(1.05)',
              backgroundColor: '#ffffff',
              color: '#000000',
            }
          }}
          leftIcon={<FaEye size={22} className="bg-transparent" />}
          className="cursor-pointer rounded-lg duration-300 ease-in-out bg-white/30"
          disabled={hiringTextTR === null ? true : false}
          onClick={open}>
          View
        </Button>
      </div>
    </form>
    <Modal
        opened={opened}
        onClose={close}
        size={'55rem'}
        centered
        withCloseButton={false}>
        <div className="flex flex-col space-y-2 p-4 bg-white">
          <div className="flex flex-row items-end justify-end space-x-2">
            {isCopied && <p className="text-black font-semibold">Copied</p>}
            {!isCopied && (
              <FaRegCopy
                onClick={handleCopyToClipboard}
                size={22}
                color="#000000"
                className="cursor-pointer self-end"
              />
            )}
            {/* <button className="font-semibold text-base font-sans" onClick={toggleLanguage}>{responseLanguage === 'EN' ? 'TR' : 'EN'}</button> */}
            {/* <div className="flex">
                <div
                  className="border border-gray-400 cursor-pointer"
                  onClick={() => setResponseLanguage("EN")}
                >
                  <p className="text-white text-base font-medium px-2 py-1">
                    EN
                  </p>
                </div>
                <div
                  className="border border-gray-400 cursor-pointer"
                  onClick={() => setResponseLanguage("TR")}
                >
                  <p className="text-white text-base font-medium px-2 py-1">
                    TR
                  </p>
                </div>
              </div> */}
          </div>
          <div className=''>
            {
              responseLanguage === "EN" ?
                // <div
                //   className="text-black font-medium font-sans text-base"
                //   dangerouslySetInnerHTML={{ __html: formattedTextEN }}
                // ></div>
                <Markdown skipHtml={false} remarkPlugins={[remarkGfm]}>{formattedTextEN}</Markdown>
                :
                // <div
                //   className="text-black font-medium font-sans text-base"
                //   dangerouslySetInnerHTML={{ __html: formattedTextTR }}
                // ></div>
                <Markdown skipHtml={false} remarkPlugins={[remarkGfm]}>{formattedTextTR}</Markdown>
            }
          </div>
        </div>
      </Modal>
      </>
  )
}

export default NewTextGenForm