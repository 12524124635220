import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isRejectedWithValue
} from "@reduxjs/toolkit";
import axios from "axios";

interface InitialStateProps {
  listingType: 'participants' | 'qualified';
  scrappedDataIsLoading: boolean;
  isScrappedDataUpToDate: boolean;
  qualifiedParticipants: null | any[];
  filteredQualifiedParticipants: null | any[];
  isQualifyingParticipants: boolean;
  selectedDescriptionID: null | string;
  loadingNewAccounts: boolean;
  loadingList: boolean;
  loadingQualify: boolean;
  activeJobPostID: null | string;
  activeAccount: null | string;
  activeJobPostParticipants: null | any[];
  accounts: null | string[];
  jobPostsList: null | any[];
  jobPostsIdList: null | any[];
  applicants: null | any[];
  hiringTextEN: null | string;
  hiringTextTR: null | string;
}

const initialState: InitialStateProps = {
  listingType: "participants",
  scrappedDataIsLoading: false,
  qualifiedParticipants: null,
  filteredQualifiedParticipants: null,
  selectedDescriptionID: null,
  isScrappedDataUpToDate: false,
  activeJobPostParticipants: null,
  isQualifyingParticipants: false,
  loadingNewAccounts: false,
  loadingList: false,
  loadingQualify: false,
  applicants: null,
  activeAccount: null,
  activeJobPostID: null,
  jobPostsList: null,
  jobPostsIdList: null,
  accounts: null,
  hiringTextEN: null,
  hiringTextTR: null,
};

export const jobPostsList = createAsyncThunk(
  "jobPostsList",
  async ({ token, account }: any, ThunkAPI) => {
    try {
      const response = await axios.post(
        `http://127.0.0.1:5000/all-jobs-with-details`,
        {
          account: account,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log(response.data);

      return response.data;
    } catch (error: any) {
      throw ThunkAPI.rejectWithValue({ message: error.response.data.errors });
    }
  }
);

export const jobPostsIdList = createAsyncThunk(
  "jobPostsIdList",
  async ({ token, account }: any, ThunkAPI) => {
    try {
      const response = await axios.post(
        `http://127.0.0.1:5000/jobs`,
        {
          account: account,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log(response.data);

      return response.data;
    } catch (error: any) {
      throw ThunkAPI.rejectWithValue({ message: error.response.data.errors });
    }
  }
);

export const qualifiedParticipants = createAsyncThunk(
  "qualifiedParticipants",
  async ({ token, url, job_desc }: any, ThunkAPI) => {
    try {
      const response = await axios.post(
        `http://127.0.0.1:5000/qualify-participants`,
        {
          url: url,
          job_desc: job_desc
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log(response.data);

      return response.data;
    } catch (error: any) {
      throw ThunkAPI.rejectWithValue({ message: error.response.data.errors });
    }
  }
);

export const applicants = createAsyncThunk(
  "applicants",
  async ({ token, jobid }: any, ThunkAPI) => {
    try {
      const response = await axios.post(
        `http://127.0.0.1:5000/list-participants`,
        {
          jobid: jobid
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log(response.data);

      return response.data;
    } catch (error: any) {
      throw ThunkAPI.rejectWithValue({ message: error.response.data.errors });
    }
  }
);

export const JobPostsSlice = createSlice({
  name: "JobPostsSlice",
  initialState,
  reducers: {

    setListingType: ((state, action: PayloadAction<'participants' | 'qualified'>) => {
      state.listingType = action.payload;
    }),
    setQualifiedParticipants: ((state, action: PayloadAction<any>) => {
      state.qualifiedParticipants = action.payload;
    }),
    setFilteredQualifiedParticipants: ((state, action: PayloadAction<any>) => {
      state.filteredQualifiedParticipants = action.payload;
    }),
    setScrappedDataIsLoading: ((state, action: PayloadAction<boolean>) => {
      state.scrappedDataIsLoading = action.payload;
    }),
    setActiveDescriptionID: ((state, action: PayloadAction<string | null>) => {
      state.selectedDescriptionID = action.payload;
    }),
    setAccountLoading: ((state, action: PayloadAction<boolean>) => {
      state.loadingNewAccounts = action.payload;
    }),
    setIsQualifyingParticipants: ((state, action: PayloadAction<boolean>) => {
      state.isQualifyingParticipants = action.payload;
    }),
    setIsScrappedDataUpToDate: ((state, action: PayloadAction<boolean>) => {
      state.isScrappedDataUpToDate = action.payload;
    }),
    setActiveAccount: ((state, action: PayloadAction<string | null>) => {
      state.activeAccount = action.payload;
    }),
    setActiveJobPostID: ((state, action: PayloadAction<string | null>) => {
      state.activeJobPostID = action.payload;
    }),
    setActiveJobPostParticipants: ((state, action: PayloadAction<any>) => {
      state.activeJobPostParticipants = action.payload;
    }),
    
    setJobPostsList: ((state, action: PayloadAction<any>) => {
      state.jobPostsList = action.payload;
    }),
    setJobPostIDList: ((state, { payload }: any) => {
      state.jobPostsIdList = payload;
    }),
    setAccounts: ((state, { payload }: any) => {
      state.accounts = payload;
    }),
    setHiringTextEN: ((state, { payload }: any) => {
      state.hiringTextEN = payload;
    }),
    setHiringTextTR: ((state, { payload }: any) => {
      state.hiringTextTR = payload;
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(qualifiedParticipants.pending, (state) => {
      state.loadingQualify = true;
      state.qualifiedParticipants = null;
    });
    builder.addCase(qualifiedParticipants.fulfilled, (state, { payload }) => {
      state.loadingQualify = false;
      state.qualifiedParticipants = payload;
    });
    builder.addCase(qualifiedParticipants.rejected, (state, action: any) => {
      state.loadingQualify = false;
    });
    builder.addCase(applicants.pending, (state) => {
      state.loadingList = true;
      state.applicants = null;
    });
    builder.addCase(applicants.fulfilled, (state, { payload }: any) => {
      state.loadingList = false;
      state.applicants = payload;
    });
    builder.addCase(applicants.rejected, (state, action: any) => {
      state.loadingList = false;
    });
  }
});

export const { setActiveAccount, setAccounts, setHiringTextEN, setHiringTextTR ,setActiveJobPostID, setJobPostsList, setFilteredQualifiedParticipants, setActiveDescriptionID, setScrappedDataIsLoading, setIsScrappedDataUpToDate, setActiveJobPostParticipants, setAccountLoading, setIsQualifyingParticipants, setQualifiedParticipants, setListingType, setJobPostIDList } = JobPostsSlice.actions;

export default JobPostsSlice.reducer;
