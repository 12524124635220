import { useToggle, upperFirst } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Text,
  Paper,
  Group,
  PaperProps,
  Button,
  Divider,
  Checkbox,
  Anchor,
  Stack,
} from '@mantine/core';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useAddAccountMutation, useListAccountsMutation } from '../../redux/services/JobPostApi';
import { setAccountLoading, setAccounts, setActiveAccount } from '../../redux/features/JobPostsSlice';

export function AddAccountForm(props: PaperProps) {

  const [addAccount, response] = useAddAccountMutation();
  const [listAccounts, response5] = useListAccountsMutation();
  const dispatch = useDispatch();
  
  const listAccountsRequest = async () => {
    try {
      const payload = await listAccounts({}).unwrap();
      console.log("List Accounts: ");
      console.log(payload.data);
      dispatch(setAccounts(payload.data))
      dispatch(setActiveAccount(payload.data[0]))
    } catch (error) {
      console.log("rejected", error);
    }
  };
  const isAccountLoading = useSelector(
    (state: RootState) => state.hirePeople.loadingNewAccounts
  );
  
  const form = useForm({
    initialValues: {
      username: '',
      password: '',
      alias: '',
    },

    validate: {
      username: (val) => (val.length >= 5 ? null : 'Invalid username'),
      alias: (val) => (val.length >= 5 ? null : 'Invalid account name'),
      password: (val) => (val.length <= 6 ? 'Password should include at least 6 characters' : null),
    },
  });

  return (
    <Paper radius="md" p="xl" withBorder {...props}>
      {/* <Text size="lg" weight={500} mb={12}>
        Enter <b className='text-lg'>Linkedin</b> account credentials
      </Text> */}


      {/* On submit yazılacak */}
      <form onSubmit={form.onSubmit(async () => {
        try {
          dispatch(setAccountLoading(true))
          
          const payload = await addAccount({ username: form.values.username, password: form.values.password, alias: form.values.alias }).unwrap()
          console.log("Add Account Response: ", payload)
          if(payload.message === 'Account with that name already exists'){
            alert('Account with that name already exists')
          }
          if(payload.status === 'success'){
            listAccountsRequest()
            alert('Account added successfully')
          }
          dispatch(setAccountLoading(false))
        } catch (error) {
          dispatch(setAccountLoading(false))
        }
      })}>
        <Stack>

          <TextInput
            required
            label="Username"
            placeholder="Your username"
            value={form.values.username}
            onChange={(event) => form.setFieldValue('username', event.currentTarget.value)}
            error={form.errors.username && 'Account name should include at least 5 characters'}
            radius="md"
          />

          <PasswordInput
            required
            label="Password"
            placeholder="Your password"
            value={form.values.password}
            onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
            error={form.errors.password && 'Password should include at least 6 characters'}
            radius="md"
            
          />
          
          <TextInput
            required
            label="Account Name"
            placeholder="Account 1"
            value={form.values.alias}
            onChange={(event) => form.setFieldValue('alias', event.currentTarget.value)}
            error={form.errors.alias && 'Account name should include at least 5 characters'}
            radius="md"
            mb={6}
          />

          <Button
            type="submit"
            loading= {isAccountLoading}
            sx={{
              "&:hover": {
                backgroundColor: 'red',
                opacity: 0.6
              }
            }}
            className='bg-red-500' >
            Add Account
          </Button>
        </Stack>

      </form>
    </Paper>
  );
}