import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState, useAppSelector } from '../store';
import Cookies from "js-cookie";
import { jobPostsList, setAccounts, setActiveAccount, setActiveJobPostID, setJobPostIDList } from '../features/JobPostsSlice';

export const jobPostApi = createApi({
  reducerPath: 'jobPostApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `https://api.hiring.ceremeet.com/`,
    // baseUrl: `http://localhost:5000/`,
    prepareHeaders: (headers, { getState }) => {
      // const token = Cookies.get("access_token");
      const token = (getState() as RootState).user?.token;

      // console.log(token)
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      headers.set('Content-Type', 'application/json')
      headers.set('Accept', '*/*')
      headers.set('Accept-Encoding', 'gzip, deflate, br')
      headers.set('Connection', 'keep-alive')
      return headers
    },
  }),

  endpoints: (builder) => ({

    // Scrapes the posted jobs of the selected linkedin account -> {"account": "linkedin_account"}
    scrapAll: builder.mutation<any, any>({
      query: (body) => ({
        url: `scrap-refresh`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),
    scrapJobPosts: builder.mutation<any, any>({
      query: (body) => ({
        url: `scrap_jobs`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),

    // Scrapes the specific posted job of the selected linkedin account -> {"account": "linkedin_account", "jobid": "id"}
    // job id should come from List Job Post IDs
    scrapJobPostPage: builder.mutation<any, any>({
      query: (body) => ({
        url: `scrap_jobpage`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),

    // Scrapes the applicants of the posted job
    scrapParticipants: builder.mutation<any, any>({
      query: (body) => ({
        url: `scrap-participants`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),

    // Adds the linkedin account to the ceremeet account
    addAccount: builder.mutation<any, any>({
      query: (body) => ({
        url: `add-account`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),

    // Lists the joined linkedin accounts to the ceremeet account
    listAccounts: builder.mutation<any, any>({
      query() {
        return {
          url: 'account-list',
          method: 'GET',
          credentials: 'include',
        };
      },
    }),

    // Lists the posted jobs with details
    getJobPosts: builder.mutation<any, any>({
      query(body) {
        return {
          url: 'all-jobs-with-details',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },

    }),

    getScrapStatus: builder.mutation<any, any>({
      query: (body) => ({
        url: `scrap-status`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),

    // Lists the posted job ids
    getJobPostIDList: builder.mutation<any, any>({
      query(data) {
        return {
          url: 'jobs',
          method: 'POST',
          body: data,
          credentials: 'include',
        };
      },
      // async onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   try {
      //     const payload =  await queryFulfilled.then( result => result.data );
      //     console.log(payload.data)
      //     dispatch(setJobPostIDList(payload.data))
      //   } catch (error) {
      //     console.log(error)
      //   }
      // },
    }),

    // Lists the applicants of the posted job
    listParticipants: builder.mutation<any, any>({
      query: (body) => ({
        url: `list-participants`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),
    // Lists the qualified applicants of the posted job
    // listQualifiedParticipants: builder.mutation<any,any>({
    //   query: (body) => ({
    //     url: `qualified-list`,
    //     method: 'POST',
    //     body,
    //     credentials: 'include',
    //   }),
    // }),

    // Post the job to the linkedin
    postJob: builder.mutation<any, any>({
      query: (body) => ({
        url: `post-application`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),

    // Qualifies the applicants of the posted job
    qualifyParticipants: builder.mutation<any, any>({
      query: (body) => ({
        url: `qualify-participants`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),

    generateJobPost: builder.mutation<any, any>({
      query: (body) => ({
        url: `ai-post-generation`,
        method: 'POST',
        body,
        credentials: 'include',
      }),
    }),

  }),
});

export const {
  useAddAccountMutation,
  useListAccountsMutation,
  useScrapJobPostsMutation,
  useScrapJobPostPageMutation,
  useScrapParticipantsMutation,
  useGetJobPostsMutation,
  useListParticipantsMutation,
  useGenerateJobPostMutation,
  usePostJobMutation,
  useQualifyParticipantsMutation,
  useScrapAllMutation,
  useGetScrapStatusMutation,
} = jobPostApi;