import { Button, Paper, Stack, Textarea } from '@mantine/core'
import { useForm } from '@mantine/form';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setIsQualifyingParticipants, setQualifiedParticipants } from '../../redux/features/JobPostsSlice';
import { useQualifyParticipantsMutation } from '../../redux/services/JobPostApi';
import { RootState } from '../../redux/store';

const QualifyParticipantsForm = ({close}:{close:any}) => {
  const dispatch = useDispatch();
  const [qualifyApplicants, response3] = useQualifyParticipantsMutation();
  const activeJobPostId = useSelector(
    (state: any) => state.hirePeople.activeJobPostID
  );
  const isQualifyingParticipants = useSelector(
    (state: RootState) => state.hirePeople.isQualifyingParticipants
  );
  const form2 = useForm({
    initialValues: {
      jobid: activeJobPostId,
      job_desc: '',
    },

    validate: {
      jobid: (val) => (val.length >= 5 ? null : 'Invalid length'),
      job_desc: (val) => (val.length >= 1 ? null : 'Invalid length'),
    },
  });
  return (
    <Paper radius="md" >
      <form onSubmit={form2.onSubmit(async () => {
        try {
          console.log("Qualifying Participants: ", form2.values)
          dispatch(setIsQualifyingParticipants(true))
          const payload = await qualifyApplicants({ jobid: activeJobPostId, job_desc: form2.values.job_desc }).unwrap()
          console.log("Qualified Participants: ", payload)
          dispatch(setQualifiedParticipants(payload.result))
          dispatch(setIsQualifyingParticipants(false))
          close()
          form2.reset()
        } catch (error) {
          dispatch(setIsQualifyingParticipants(false))
          console.log('rejected', error)
        }
      })}>
        <Stack>
          <Textarea
            required
            label="Description"
            placeholder="Describe job qualification criteria."
            value={form2.values.job_desc}
            onChange={(event) => form2.setFieldValue('job_desc', event.currentTarget.value)}
            error={form2.errors.job_desc && 'Description should include at least 20 characters'}
            radius="md"
          />
          <Button
            type="submit"
            loading={isQualifyingParticipants}
            // disabled={isQualifyingParticipants}
            sx={{
              "&:hover": {
                backgroundColor: 'green',
                opacity: 0.6
              }
            }}
            className='bg-green-500' >
            {isQualifyingParticipants === true ? 'Qualifying Participants' : 'Qualify Participants'}
          </Button>
        </Stack>

      </form>
    </Paper>
  )
}

export default QualifyParticipantsForm