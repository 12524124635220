import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import UserSlice from "./features/userSlice";
import JobPostsSlice from "./features/JobPostsSlice";
import JobPostCreationSlice from "./features/JobPostCreationSlice";
import { jobPostApi } from "./services/JobPostApi";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  [jobPostApi.reducerPath]: jobPostApi.reducer,
  hiring: JobPostCreationSlice,
  user: UserSlice,
  hirePeople: JobPostsSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck:false}).concat([jobPostApi.middleware]),
})

const persistor = persistStore(store);

export { store, persistor };
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
