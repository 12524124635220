import React, { useEffect, useState } from "react";
import { mockJobPosts } from "../../mockPeople";
import AddJobPost from "../buttons/AddJobPost";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../redux/store";
import { Loader, Modal, Paper, PaperProps, Skeleton, Stack, TextInput, Textarea } from '@mantine/core'
import JobPostBox from "../boxes/JobPostBox";
import { useGetJobPostsMutation, useGetScrapStatusMutation, useQualifyParticipantsMutation, useScrapAllMutation, useScrapJobPostPageMutation, useScrapParticipantsMutation } from "../../redux/services/JobPostApi";
import { jobPostsList, applicants, setScrappedDataIsLoading, setIsQualifyingParticipants, setJobPostsList, setJobPostIDList, setQualifiedParticipants } from "../../redux/features/JobPostsSlice";
import Cookies from "js-cookie";
import { getJSDocOverrideTagNoCache } from "typescript";
import { Button } from "@mantine/core";
import { IJobPostsItem, IParticipant } from "../../types/JobPostTypes";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import QualifyParticipantsForm from "../forms/QualifyParticipantsForm";
import QualifiedParticipantsBox from "../boxes/QualifiedParticipantsBox";
import ParticipantsBox from "../boxes/ParticipantsBox";
import FilterDecriptionList from "../lists/FilterDecriptionList";
import { AddAccountForm } from "../forms/AddAccountForm";
import QualificationDescriptionBox from "../boxes/QualificationDescriptionBox";

function JobPostsList(props: PaperProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const token = Cookies.get("access_token");
  const [jobPosts, setJobPosts] = useState<[] | null>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);
  const [jobLink, setJobLink] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [loadingQualifiedParticipants, setLoadingQualifiedParticipants] = useState(false)
  const dispatch = useAppDispatch();

  const listingType = useSelector(
    (state: RootState) => state.hirePeople.listingType
  );
  const activeAccount = useSelector(
    (state: RootState) => state.hirePeople.activeAccount
  );
  const qualifiedParticipants = useSelector(
    (state: RootState) => state.hirePeople.qualifiedParticipants
  );
  const filteredQualifiedParticipants = useSelector(
    (state: RootState) => state.hirePeople.filteredQualifiedParticipants
  );
  const activeJobPostId = useSelector(
    (state: any) => state.hirePeople.activeJobPostID
  );
  const isQualifyingParticipants = useSelector(
    (state: RootState) => state.hirePeople.isQualifyingParticipants
  );
  const activePostParticipants = useSelector(
    (state: any) => state.hirePeople.activeJobPostParticipants
  );
  const isScrapping = useSelector(
    (state: RootState) => state.hirePeople.scrappedDataIsLoading
  );
  const jobPostsList = useSelector(
    (state: RootState) => state.hirePeople.jobPostsList
  );


  // const [listQualifiedParticipants, response3] = useListQualifiedParticipantsMutation();

  // console.log("Active Account: ", activeAccount)
  // Scrap job posts for the active account;
  const [scrapJobPosts, response2] = useScrapJobPostPageMutation();

  // List details of job posts for the active account
  const [checkScrapStatus, response5] = useGetScrapStatusMutation();

  // List details of job posts for the active account
  const [getJobPosts, response] = useGetJobPostsMutation();

  // Scraps all the posts and participants for the active account
  const [scrapAll, response4] = useScrapAllMutation();

  const scrapAllPost = async () => {
    try {
      if (isScrapping === false) {
        dispatch(setScrappedDataIsLoading(true))
        const payload = await scrapAll({ "account": activeAccount }).unwrap()
        console.log("Scrapped All Response: ")
        console.log(payload)
      }
      const payload2 = await checkScrapStatus({ "account": activeAccount }).unwrap()
      if (payload2?.data.status === false) {
        dispatch(setScrappedDataIsLoading(false))
      }
    } catch (error) {
      dispatch(setScrappedDataIsLoading(false))
      console.log('rejected', error)
    }
  }

  const isScrappingData = async () => {
    const payload2 = await checkScrapStatus({ "account": activeAccount }).unwrap()
    if (payload2?.data.status === false) {
      dispatch(setScrappedDataIsLoading(false))
    }
    else if (payload2?.data.status === true) {
      dispatch(setScrappedDataIsLoading(true))
    }
  }
  // Get all the posts and participants for the active account
  const getJobPostsList = async () => {
    try {
      if (isScrapping === false) {
        const payload = await getJobPosts({ "account": activeAccount }).unwrap()
        console.log("Job Posts List: ")
        console.log(activeAccount)
        // dispatch(setJobPostIDList(payload.data))
        dispatch(setJobPostsList(payload.data))
        console.log(payload.data)
        setJobPosts(payload.data)
      }
    } catch (error) {
      setIsLoadingPosts(false)
      console.log('rejected', error)
    }
  }


  useEffect(() => {
    isScrappingData()
    getJobPostsList()
    console.log("All Filtered Participants: ", filteredQualifiedParticipants);
    // console.log("All Qualified Participants: ", generalQualifiedParticipants);
    console.log("Active Post Participants: ", activePostParticipants)
    // console.log("Job Posts List: ", jobPosts)
    // console.log("Active Account: ", activeAccount)
  }, [isScrapping, filteredQualifiedParticipants, activePostParticipants, activeAccount]) 

  return (
    isScrapping === true ?
      // <Skeleton w="80%" h={'60vh'} circle animate visible={isScrapping} />
      <Loader color="white" size={"lg"} />
      :
      <>
        {activeAccount === null || activeAccount === undefined ?
          <div className="flex flex-col justify-start items-center h-[80vh] pt-8 relative">
            <p className="text-white text-xl font-semibold mb-8">Please add an account to continue</p>
            <AddAccountForm />
          </div>
          :
          <>
            <div className=" w-5/6 grid grid-cols-3 gap-1 grid-row-3  mx-auto pb-4 pt-6 h-[80vh]">
              {/* <div className="flex flex-row justify-between col-span-3">
          <h1 className="text-white text-xl font-semibold mr-4">My Job Posts</h1>
          <div className="flex flex-row items-center">
            <Button className="w-32 mr-2  inline-flex justify-center rounded-md border border-transparent bg-stone-200 px-4 py-2 text-sm font-medium text-black hover:bg-stone-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-stone-500 focus-visible:ring-offset-2 " onClick={open} >
              Qualify Participants
            </Button>
            <Button className="w-32 mr-2 inline-flex justify-center rounded-md border border-transparent bg-stone-200 px-4 py-2 text-sm font-medium text-black hover:bg-stone-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-stone-500 focus-visible:ring-offset-2" onClick={getJobPostsList} loading={isLoadingPosts} >
              {isLoadingPosts ? "Listing" : "List Posts"}
            </Button>
            <Button disabled={isDataUpToDate || isLoading} className="w-32  inline-flex justify-center rounded-md border border-transparent bg-stone-200 px-4 py-2 text-sm font-medium text-black hover:bg-stone-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-stone-500 focus-visible:ring-offset-2 mr-2" onClick={scrapAllPost} loading={isScrapping} >
              {isScrapping === true ? "Loading" :
                isDataUpToDate === true ? "Up to Date" : "Load Data"}
            </Button>
          </div>
            </div> */}

              <div className="flex flex-col col-span-1 col-start-1 row-start-1 row-span-3 justify-start items-center overflow-y-scroll ">
                {jobPosts?.map((jobPost: IJobPostsItem) => (
                  <JobPostBox
                    open={open}
                    url={jobPost.url}
                    aciklama={jobPost.aciklama}
                    email={jobPost.email}
                    is_turu={jobPost.is_turu}
                    jobid={jobPost.jobid}
                    konum={jobPost.konum}
                    sirket={jobPost.sirket}
                    unvan={jobPost.unvan}
                    participants={jobPost.participants}
                    qualified={jobPost.qualified}
                    key={jobPost.jobid}
                  />
                ))}
              </div>

              <div className="flex flex-row  row-start-1 row-end-2 row-span-1 col-start-2 col-span-2 items-start justify-start">
                <div className='flex flex-row items-start  w-full h-auto min-h-[80px] space-x-3 my-2 mx-2 py-3 px-1 rounded-md '>
                  {
                    filteredQualifiedParticipants !== null && filteredQualifiedParticipants?.length > 0 ?
                      filteredQualifiedParticipants?.map((item2: any, index: number) =>
                        // iterates over the job posts descriptions
                        <QualificationDescriptionBox
                          key={item2?.descid + item2?.jobid + Math.random()}
                          order={index + 1}
                          description={item2?.description}
                          descid={item2?.descid}
                          list={item2?.list}
                        />
                      )
                      :
                      <div className='flex flex-row items-center justify-center w-full h-full'>
                        <span className='text-lg font-semibold text-center'>Add Filter to selected Job Post</span>
                      </div>
                  }
                </div>
              </div>

              <div className="col-span-2 col-start-2 row-start-2 row-end-4 col-end-4 flex flex-row justify-center items-center w-full relative h-full max-h-[60vh]">
                <div className="flex flex-row justify-items-start flex-wrap xl:max-w-[780px] 2xl:max-w-[1030px] overflow-y-scroll h-full ">
                  {
                    listingType === 'qualified' ?
                      qualifiedParticipants?.map((participant: IParticipant, index: any) => (
                        <QualifiedParticipantsBox key={participant?.id} id={participant?.id} url={participant?.url} img={participant?.img} name={participant?.name} order={index + 1} />
                      )) :
                      activePostParticipants?.map((participant: IParticipant, index: any) => (
                        <ParticipantsBox key={participant?.id} id={participant?.id} img={participant?.img} url={participant?.url} name={participant?.name} />
                      ))
                  }
                </div>
              </div>
            </div>
            <Modal opened={opened} h={'auto'} onClose={close} centered withCloseButton={false}>
              <QualifyParticipantsForm close={close} />
            </Modal>
          </>
        }
      </>
  );
}

export default JobPostsList;
