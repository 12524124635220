import React, { useEffect } from 'react'
import { useAppDispatch } from '../../redux/store';
import { setActiveDescriptionID, setListingType, setQualifiedParticipants } from '../../redux/features/JobPostsSlice';
import { useSelector } from 'react-redux';

const QualificationDescriptionBox = ({ order, description, descid, list }: { order: number, description: string, descid: string, list: any }) => {

  const dispatch = useAppDispatch();
  const activeJobPostId = useSelector(
    (state: any) => state.hirePeople.activeJobPostID
  );

  const selectedDescriptionID = useSelector(
    (state: any) => state.hirePeople.selectedDescriptionID
  );




  useEffect(() => {
    console.log("Listing", list)
    console.log("Descid: ", descid)
    console.log("Selected descid: ", selectedDescriptionID)
  }, [])
  

  const showQualifiedParticipants = () => {
    console.log("Clicked: ", descid)
    if (activeJobPostId !== null) {
      dispatch(setActiveDescriptionID(descid))
      dispatch(setQualifiedParticipants(list))
    }
  }

  return (
    <button className={`flex flex-col items-start justify-center w-48 bg-white p-2 rounded-md ${descid === selectedDescriptionID ? 'ring-2 ring-green-300' : ''}`}
      onClick={showQualifiedParticipants}
    >
      <span className='text-semibold w-full truncate text-lg text-center font-semibold'>{order}</span>
      <span className='text-md w-full truncate'>{description}</span>
    </button>
  )
}

export default QualificationDescriptionBox