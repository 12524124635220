import Footer from "./components/sections/Footer";
import HeroSection from "./components/sections/HeroSection";
import HiringInputs from "./components/HiringInputs";
import Navbar from "./components/Navbar";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import { useIsAuthenticated } from "react-auth-kit";
import { GoogleOAuthProvider } from "@react-oauth/google";
import HirePeoplePage from "./pages/HirePeoplePage";
function App() {
  const clientId =
    "331611527191-q8bq3m7qq8e39c9j0sgl100up58vu92h.apps.googleusercontent.com";
  const PrivateRoute = ({ Component }: any) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? Component : <Navigate to="/login" />;
  };
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          {/* <Route path="/register" element={<RegisterPage />} /> */}
          <Route path="/" element={<PrivateRoute Component={<HomePage />} />} />
          <Route
            path="/hire-people"
            element={<PrivateRoute Component={<HirePeoplePage />} />}
          />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
