import React from 'react'

const ParticipantsBox = ({id,img,name,url}:{id:string,img:string,name:string, url:string}) => {
  return (
    <a href={`https://linkedin.com${url}`} target='_blank' className="flex flex-col items-center justify-center shadow-md m-2 relative bg-white/80 hover:bg-white/90 hover:cursor-pointer rounded-md w-[155px] h-[155px] transition duration-300 ease-in-out">
      <img src={img} alt="" className="w-28 h-28 rounded-full shadow-xl" />
      <h1 className="text-black text-sm font-semibold w-full truncate px-2 text-center">{name}</h1>
    </a>
  )
}

export default ParticipantsBox