export interface IParticipant {
    experience: string[];
    id: string;
    img: string;
    job_title: string;
    name: string;
    place: string;
    profile_url: string;
    url: string;
  }
  
  export interface IJobPostsItem{
    open: any;
    aciklama: string;
    email: string;
    is_turu: string;
    jobid: string;
    konum: string;
    sirket: string;
    unvan: string;
    participants: IParticipant[];
    qualified: [] | null;
    url: string;
  }

  export enum LanguagesEnumType {
    ENGLISH = "English",
    SPANISH = "Spanish",
    FRENCH = "French",
    GERMAN = "German",
    ITALIAN = "Italian",
    PORTUGUESE = "Portuguese",
    RUSSIAN = "Russian",
    CHINESE = "Chinese",
    JAPANESE = "Japanese",
    KOREAN = "Korean",
    ARABIC = "Arabic",
    HINDI = "Hindi",
    TURKISH = "Turkish",
    GREEK = "Greek",
    UKRAINIAN = "Ukrainian",
    POLISH = "Polish",
    DUTCH = "Dutch",
    AFRICANS = "Africans",
    NULL = "",
  }
  
  export enum DegreeEnumType {
    HIGHSCHOOL = "High School",
    ASSOCIATES = "Associates",
    BACHELORS = "Bachelors",
    MASTERS = "Masters",
    PHD = "PhD",
  }
  
  export enum JobTypeEnumType {
    FULLTIME = "Full-time",
    PARTTIME = "Part-time",
    CONTRACT = "Contract",
    TEMPORARY = "Temporary",
    INTERNSHIP = "Internship",
    VOLUNTEER = "Volunteer",
    OTHER = "Other",
  }
  
  export enum ExperienceLevelEnumType {
    ENTRYLEVEL = "Entry level",
    ASSOCIATE = "Associate",
    MIDSENIORLEVEL = "Mid-Senior level",
    DIRECTOR = "Director",
    EXECUTIVE = "Executive",
    INTERNSHIP = "Internship",
    NOTAPPLICABLE = "Not Applicable",
  }
  
  export enum CompanyNameEnumType {
    BILISIM = "Eczacıbaşı Bilişim",
    ECOM = "Eczacıbaşı Ekom",
    GAYRIMENKUL = "Eczacıbaşı Gayrimenkul",
    DECOVERSE = "Decoverse",
    ECZACIBASI = "Eczacıbaşı Holding",
    ILAC = "Eczacıbaşı İlaç Pazarlama",
    ILACSINAYI = "Eczacıbaşı İlaç, Sinai ve Finansal Yatırımlar",
    TUKETIM = "Eczacıbaşı Tüketim Ürünleri",
    YAPI = "Eczacıbaşı Yapı Gereçleri",
    VITRA = "VitrA Karo",
    INTEMA = "İntema",
    GENSENTA = "Gensenta",
    ESAN = "Esan",
    MONROL = "Eczacıbaşı-Monrol Nükleer Ürünler",
  }
  
  export enum JobFunctionEnumType {
    ACCOUNTING = "Accounting",
    ADMINISTRATIVE = "Administrative",
    ARTSANDDESIGN = "Arts and Design",
    BUSINESSDEVELOPMENT = "Business Development",
    COMMUNITYANDSOCIALSERVICES = "Community and Social Services",
    CONSULTING = "Consulting",
    EDUCATION = "Education",
    ENGINEERING = "Engineering",
    ENTREPRENEURSHIP = "Entrepreneurship",
    FINANCE = "Finance",
    HEALTHCARESERVICES = "Healthcare Services",
    HUMANRESOURCES = "Human Resources",
    INFORMATIONTECHNOLOGY = "Information Technology",
    LEGAL = "Legal",
    MARKETING = "Marketing",
    MEDIAANDCOMMUNICATION = "Media and Communication",
    MILITARYANDPROTECTIVESERVICES = "Military and Protective Services",
    OPERATIONS = "Operations",
    PRODUCTMANAGEMENT = "Product Management",
    PROGRAMANDPROJECTMANAGEMENT = "Program and Project Management",
    PURCHASING = "Purchasing",
    QUALITYASSURANCE = "Quality Assurance",
    REALESTATE = "Real Estate",
    RESEARCH = "Research",
    SALES = "Sales",
    SUPPORT = "Support",
    OTHER = "Other",
  }
  
  export enum WorkSpaceEnumType {
    ONSIDE = "On-Side",
    REMOTE = "Remote",
    HYBRID = "Hybrid",
  }
  
  export enum CompanyIndustryEnumType {
    EDUCATION_MANAGEMENT = "Education Management",
    E_LEARNING = "E-Learning",
    HIGHER_EDUCATION = "Higher Education",
    PRIMARY_SECONDARY_EDUCATION = "Primary/Secondary Education",
    RESEARCH = "Research",
    BUILDING_MATERIALS = "Building Materials",
    CIVIL_ENGINEERING = "Civil Engineering",
    CONSTRUCTION = "Construction",
    ARCHITECTURE_PLANNING = "Architecture & Planning",
    DESIGN = "Design",
    GRAPHIC_DESIGN = "Graphic Design",
    ACCOUNTING = "Accounting",
    BUSINESS_SUPPLIES_EQUIPMENT = "Business Supplies & Equipment",
    ENVIRONMENTAL_SERVICES = "Environmental Services",
    EVENTS_SERVICES = "Events Services",
    EXECUTIVE_OFFICE = "Executive Office",
    FACILITIES_SERVICES = "Facilities Services",
    HUMAN_RESOURCES = "Human Resources",
    INFORMATION_SERVICES = "Information Services",
    MANAGEMENT_CONSULTING = "Management Consulting",
    OUTSOURCING_OFFSHORING = "Outsourcing/Offshoring",
    PROFESSIONAL_TRAINING_COACHING = "Professional Training & Coaching",
    SECURITY_INVESTIGATIONS = "Security & Investigations",
    STAFFING_RECRUITING = "Staffing & Recruiting",
    RETAIL = "Retail",
    SUPERMARKETS = "Supermarkets",
    WHOLESALE = "Wholesale",
    MINING_METALS = "Mining & Metals",
    OIL_ENERGY = "Oil & Energy",
    UTILITIES = "Utilities",
    AUTOMOTIVE = "Automotive",
    AVIATION_AEROSPACE = "Aviation & Aerospace",
    CHEMICALS = "Chemicals",
    DEFENSE_SPACE = "Defense & Space",
    ELECTRICAL_ELECTRONIC_MANUFACTURING = "Electrical & Electronic Manufacturing",
    FOOD_PRODUCTION = "Food Production",
    GLASS_CERAMICS_CONCRETE = "Glass, Ceramics & Concrete",
    INDUSTRIAL_AUTOMATION = "Industrial Automation",
    MACHINERY = "Machinery",
    MECHANICAL_INDUSTRIAL_ENGINEERING = "Mechanical or Industrial Engineering",
    PACKAGING_CONTAINERS = "Packaging & Containers",
    PAPER_FOREST_PRODUCTS = "Paper & Forest Products",
    PLASTICS = "Plastics",
    RAILROAD_MANUFACTURE = "Railroad Manufacture",
    RENEWABLES_ENVIRONMENT = "Renewables & Environment",
    SHIPBUILDING = "Shipbuilding",
    TEXTILES = "Textiles",
    BANKING = "Banking",
    CAPITAL_MARKETS = "Capital Markets",
    FINANCIAL_SERVICES = "Financial Services",
    INSURANCE = "Insurance",
    INVESTMENT_BANKING = "Investment Banking",
    INVESTMENT_MANAGEMENT = "Investment Management",
    VENTURE_CAPITAL_PRIVATE_EQUITY = "Venture Capital & Private Equity",
    AIRLINES_AVIATION = "Airlines/Aviation",
    GAMBLING_CASINOS = "Gambling & Casinos",
    HOSPITALITY = "Hospitality",
    LEISURE_TRAVEL_TOURISM = "Leisure, Travel & Tourism",
    RESTAURANTS = "Restaurants",
    RECREATIONAL_FACILITIES_SERVICES = "Recreational Facilities & Services",
    SPORTS = "Sports",
    ARTS_CRAFTS = "Arts & Crafts",
    FINE_ART = "Fine Art",
    PERFORMING_ARTS = "Performing Arts",
    PHOTOGRAPHY = "Photography",
    BIOTECHNOLOGY = "Biotechnology",
    HOSPITAL_HEALTH_CARE = "Hospital & Health Care",
    MEDICAL_DEVICE = "Medical Device",
    MEDICAL_PRACTICE = "Medical Practice",
    MENTAL_HEALTH_CARE = "Mental Health Care",
    PHARMACEUTICALS = "Pharmaceuticals",
    VETERINARY = "Veterinary",
    COMPUTER_HARDWARE = "Computer Hardware",
    COMPUTER_NETWORKING = "Computer Networking",
    NANOTECHNOLOGY = "Nanotechnology",
    SEMICONDUCTORS = "Semiconductors",
    TELECOMMUNICATIONS = "Telecommunications",
    WIRELESS = "Wireless",
    COMMERCIAL_REAL_ESTATE = "Commercial Real Estate",
    REAL_ESTATE = "Real Estate",
    ALTERNATIVE_DISPUTE_RESOLUTION = "Alternative Dispute Resolution",
    LAW_PRACTICE = "Law Practice",
    LEGAL_SERVICES = "Legal Services",
    APPAREL_FASHION = "Apparel & Fashion",
    CONSUMER_ELECTRONICS = "Consumer Electronics",
    CONSUMER_GOODS = "Consumer Goods",
    CONSUMER_SERVICES = "Consumer Services",
    COSMETICS = "Cosmetics",
    FOOD_BEVERAGES = "Food & Beverages",
    FURNITURE = "Furniture",
    LUXURY_GOODS_JEWELRY = "Luxury Goods & Jewelry",
    SPORTING_GOODS = "Sporting Goods",
    TOBACCO = "Tobacco",
    WINE_SPIRITS = "Wine and Spirits",
    DAIRY = "Dairy",
    FARMING = "Farming",
    FISHERY = "Fishery",
    RANCHING = "Ranching",
    MARKET_RESEARCH = "Market Research",
    MARKETING_ADVERTISING = "Marketing & Advertising",
    NEWSPAPERS = "Newspapers",
    ONLINE_MEDIA = "Online Media",
    PRINTING = "Printing",
    PUBLIC_RELATIONS_COMMUNICATIONS = "Public Relations & Communications",
    PUBLISHING = "Publishing",
    TRANSLATION_LOCALIZATION = "Translation & Localization",
    WRITING_EDITING = "Writing & Editing",
    CIVIC_SOCIAL_ORGANIZATION = "Civic & Social Organization",
    FUNDRAISING = "Fundraising",
    INDIVIDUAL_FAMILY_SERVICES = "Individual & Family Services",
    INTERNATIONAL_TRADE_DEVELOPMENT = "International Trade & Development",
    LIBRARIES = "Libraries",
    MUSEUMS_INSTITUTIONS = "Museums & Institutions",
    NON_PROFIT_ORGANIZATION_MANAGEMENT = "Non-Profit Organization Management",
    PHILANTHROPY = "Philanthropy",
    PROGRAM_DEVELOPMENT = "Program Development",
    RELIGIOUS_INSTITUTIONS = "Religious Institutions",
    THINK_TANKS = "Think Tanks",
    COMPUTER_NETWORK_SECURITY = "Computer & Network Security",
    COMPUTER_SOFTWARE = "Computer Software",
    INFORMATION_TECHNOLOGY_SERVICES = "Information Technology & Services",
    INTERNET = "Internet",
    IMPORT_EXPORT = "Import & Export",
    LOGISTICS_SUPPLY_CHAIN = "Logistics & Supply Chain",
    MARITIME = "Maritime",
    PACKAGE_FREIGHT_DELIVERY = "Package/Freight Delivery",
    TRANSPORTATION_TRUCKING_RAILROAD = "Transportation/Trucking/Railroad",
    WAREHOUSING = "Warehousing",
    ANIMATION = "Animation",
    BROADCAST_MEDIA = "Broadcast",
  }