import React from "react";
import cere from "../../assets/cere.png";
function HeroSection() {
  return (
    <div className="flex mx-auto justify-center  items-center">
      <div className="w-full sm:w-1/2 flex lg:space-x-4 items-center px-5 lg:px-0 ">
        <img src={cere} alt="cere" className="w-1/3" />
        <div className="flex flex-col items-center w-2/3 space-y-2">
          <div className="flex flex-col sm:space-y-4  ">
            <h1 className="text-sm sm:text-lg md:text-xl lg:text-4xl font-bold text-white text-center">
              Cere provides valuable assistance with your recruitment needs.
            </h1>
            <h3 className="text-lg text-white font-medium text-center hidden sm:block">
              Cere is here to create the perfect job posting for you, tailored
              to meet your specific criteria. Rest assured, your job posting
              will be prepared promptly and efficiently.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
