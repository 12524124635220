import React from "react";
import Navbar from "../components/sections/Navbar";
import Footer from "../components/sections/Footer";
import SelectedPeopleList from "../components/lists/SelectedPeopleList";
import JobPostsList from "../components/lists/JobPostsList";
import AccountListBox from "../components/boxes/AccountListBox";
import NewNavbar from "../components/sections/NewNavbar";

function HirePeoplePage() {
  return (
    <div className="bg-gradient-to-t from-cyan-500 to-zinc-600 min-h-screen max-h-screen flex flex-col relative">
      <div className="flex-grow flex flex-col space-y-4 h-full items-center">
        {/* <Navbar /> */}
        <NewNavbar />
        <JobPostsList />
        {/* <SelectedPeopleList /> */}
      </div>
      <Footer />
    </div>
  );
}

export default HirePeoplePage;
